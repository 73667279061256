import React from 'react';
import CheckoutProvider from 'components/commercetools-ui/organisms/checkout/provider';
import AddressForm from './address-form';

const CheckoutWrapped: React.FC = async () => {
  return <AddressForm />;
};

const Checkout: React.FC = () => (
  <CheckoutProvider>
    <CheckoutWrapped />
  </CheckoutProvider>
);

export default Checkout;
